<template>
  <div>
    <div class="main">
      <swiper :options="swiperOption">
        <template v-for="(item, idx) in banners">
          <swiper-slide :key="idx" v-if="item.file_type == 'video'">
            <div style="position: relative">
              <video class="video" style="" playsinline="true" preload="metadata" muted="muted" loop="loop" autoplay
                :poster="item.coverimage">
                <source :src="item.poster_file" type="video/mp4" />
              </video>
            </div>
          </swiper-slide>
          <swiper-slide v-if="item.file_type == 'image'" :key="idx">
            <div class="banner" :style="styleObj">
              <img :src="item.poster_file" />
            </div>
          </swiper-slide>
        </template>
      </swiper>
      <div class="swiper-pagination"></div>
      <!-- <div class="swiper-button-prev my-custom-prev" :style="top"></div>
      <div class="swiper-button-next my-custom-next" :style="top"></div> -->
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  props: {
    banners: {
      type: Array,
      default: function () {
        return [];
      },
    },
    swiperOption: {
      type: Object,
      default: function () {
        return {
          autoplay: {
            delay: 12000,
          },
          // navigation: {
          //   nextEl: ".my-custom-next",
          //   prevEl: ".my-custom-prev",
          // },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            bulletClass: "my-bullet",
            bulletActiveClass: "my-bullet-active",
            renderBullet: function (index, className) {
              return (
                `<div class="${className}" ><svg class="circle" viewBox="0 0 100 100">
                          <circle
                           class="circle0"
                              cx="50"
                              cy="50"
                              r="45"
                              stroke="white"
                              stroke-width="5"
                            stroke-opacity="0.2"
                              fill="transparent"
                            ></circle>
                            <circle
                            class="circle1"
                              cx="50"
                              cy="50"
                              r="45"
                              stroke="white"
                              stroke-width="5"
                              stroke-dasharray="282.74"
                              stroke-dashoffset="282.74"
                              fill="transparent"
                            ></circle>
                            <span class="num">${index+1}</span>
                          </svg></div>`
              );
            },
          },

        };
      },
    },
    styleObj: {
      type: Object,
      default: function () {
        return {
          width: '100vw',
          height: '100vh'
        }
      }
    },
    top: {
      type: Object,
      default: function () {
        return {
          top: '50%'
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style>
@keyframes drawCircle {
  0% {
    stroke-dashoffset: 282.74;
    /* Circumference of the circle */
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.my-bullet {
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3vw !important;
  height: 3vw !important;
  border-radius: 2vw !important;

  color: white;
  border-radius: 2px;
  margin-left: 0.2vw;
  cursor: pointer;
  position: relative;

  .num {
    position: relative;
    right: 1.5vw;
    font-size: 1vw;
    opacity: 0.8;
  }

}

.my-bullet-active .circle .circle1 {
  animation: drawCircle 12s linear forwards;
}


</style>

<style lang="scss" scoped>
@media (min-width: 768px) {

  .main {
    position: relative;

    .swiper-pagination-bullets {
      display: flex;
      width: 96vw;
      position: absolute;
      bottom: 2vw;
      justify-content: end;
    }


    // position: relative;
    .my-custom-prev {
      width: 3.125vw;
      height: 3.125vw;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../assets/img/left-icon.png");
    }

    .my-custom-prev::after {
      content: "";
    }

    .my-custom-prev:hover {
      background-image: url("../assets/img/swiper-prev-head-active.png");
    }

    .my-custom-next {
      width: 3.125vw;
      height: 3.125vw;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../assets/img/right-icon.png");
    }

    .my-custom-next::after {
      content: "";
    }

    .my-custom-next:hover {
      background-image: url("../assets/img/swiper-next-head-active.png");
    }
  }

  .banner {
    width: 100vw;
    height: 100vh;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .video {
    width: 100vw;
    height: 50vw;
    object-fit: cover;
  }

  .banner {
    width: 100vw;
    height: 50vw;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  /* 隐藏轮播导航按钮 */
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}
</style>