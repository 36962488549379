<template>
  <div class="tb">
    <h1 class="custom-title">{{ title }}</h1>
  </div>
</template>
  
  <script>
export default {
  props: {
    title: {
      type: String,
      default: "", // 默认标题
    },
  },
};
</script>
  
  <style scoped>
.custom-title {
  font-weight: normal;
  font-size: 2vw; /* 自适应字体大小 */
  color: #333; /* 字体颜色 */
  margin: 0; /* 消除默认外边距 */
  text-align: center; /* 根据需要调整对齐方式 */
}

.tb {
  margin-top: 2vw;
  margin-left: 2vw;
}
</style>
  