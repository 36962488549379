import service from "@/libs/request";

export function getBrands(){
    return service.request({
        method:'get',
        url:'/api/index/getProductByBrandHome'
    })
}

export function getCategory(){
    return service.request({
        method:'get',
        url:'/api/index/getProductByCateHome'
    })
}

