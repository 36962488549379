import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

// 导入语言文件
import en from '@/locales/en.json';
import zh from '@/locales/zh.json';

// 创建 i18n 实例
const i18n = new VueI18n({
  locale: 'zh', // 设置默认语言
  messages: {
    en,
    zh
  }
});

export default i18n;
