import service from "@/libs/request";

export function getEpisodeList(params) {
    return service.request({
        method: 'get',
        url: '/api/index/getProductByBrandHome',
        params
    })
}

export function getResponse() {
    return service.request({
        method: 'get',
        url: '/api/index/getResponse',
    })
}
// 获取产品中心数据
export function getProductCenter() {
    return service.request({
        method: 'get',
        url: '/api/index/getPcateIndex',
    });
}

// 获取产品中心的产品列表
export function getProductList(params) {
    return service.request({
        method: 'get',
        url: '/api/index/getProductByCate',
        params
    });
}

// 获取品牌中心的产品列表
export function getProductByBrand(params) {
    return service.request({
        method: 'get',
        url: '/api/index/getProductByBrand',
        params
    });
}
// http://cs4.ebaitop.com/api/index/getProductByBrand?limit=12&brand_id=1&page=1


// 获取品牌中心数据
export function getBrandCenter(params) {
    return service.request({
        method: 'get',
        url: '/api/index/getBrandIndex',
        params
    });
}

// 获取品牌列表
export function getBrandList(params) {
    return service.request({
        method: 'get',
        url: '/api/index/getBrand',
        params
    });
}

// 加入我们
export function getJoinUsPosts(params) {
    return service.request({
        method: 'get',
        url: '/api/index/getPost',
        params
    });
}

// 常见问题
export function getFaqList() {
    return service.request({
        method: 'get',
        url: '/api/index/problemList',
    });
}

// 下载中心分类
export function getDownloadCate(params) {
    return service.request({
        method: 'get',
        url: '/api/index/getDownLoadCate',
        params
    });
}

// 下载中心
export function getDownloadFiles(params) {
    return service.request({
        method: 'get',
        url: '/api/index/downloadFile',
        params
    });
}
// 联系我们
export function getIndexLink(params) {
    return service.request({
        method: 'get',
        url: '/api/index/link',
        params
    });
}

//员工关怀
export function getIndexStaffCare(params) {
    return service.request({
        method: 'get',
        url: '/api/index/staffCare',
        params
    });
}

//员工关怀-2.3.4.9屏
export function getIndexStaffCareContent(params) {
    return service.request({
        method: 'get',
        url: '/api/index/staffCareContent',
        params
    });
}


