<template>
  <div>
    <div class="heder-container">
      <div class="section-header" :class="showBack ? 'heder-container-white' : ''">
        <div class="part-search" v-show="search">
          <div class="search-logo">
            <img src="@/assets/img/logo.png" alt="" />
          </div>
          <div class="search-input">
            <img src="" alt="" class="input-icon" />
            <input type="text" placeholder="搜索" name="keyword" v-model="searchData" class="search-keyword"
              @keyup.enter="searchProduct" />
          </div>
          <div class="close" @click="closeSearch"></div>
        </div>

        <div class="product-drop product-display" ref="productDrop"
          :style="{ height: currentNav == 1 ? showHeight : '0' }">
          <!-- <div class="title" @mouseover="productDropFunc(1)" @mouseout="productDropOutFunc(1)">
            <span>企顺产品</span>
            <div class="closeNav"></div>
          </div> -->
          <div class="part-product-nav" @mouseover="productDropFunc(1)" @mouseout="productDropOutFunc(1)">

            <div style="display: flex;flex-direction: column;">
              <div style="display: flex;width: 90vw;margin: auto; justify-content: space-between;">
                <div v-for="(item, idx) in cate1" :key="idx"
                  style="display: flex;flex-direction: column;align-items: center;" @click="navPage(item.id)">
                  <img class="header-category" style="" :src="item.iocncheckimage">
                  <div style="height: 1vw;"></div>
                  <div style="font-size: 1vw;">{{ item.name }}</div>
                </div>
              </div>

              <div
                style="width: 87vw;margin: auto;text-align: right;margin-top: 3vw;font-size: 1vw;position: relative;">
                <div class="mote" style="" @click="navMote">mote</div>
              </div>
              <div style="height: 3vw;"></div>
            </div>
            <!-- <div class="nav-icrophone nav-product-list microphone" href="/microphone"
                            data-code="microphone">
                            <div class="prdouctName">{{ $t('header.productName') }}</div>
                            <div class="head-product-bottom">
                                <div class="nav-product-left">

                                    <template v-for="(item, idx) in brands">
                                        <div class="tab-product " :class="idx == brandsIdx ? 'active' : ''" :key="idx">
                                            <a :href="'/catelist?brand_id=' + item.id" @mouseover="changeBrandIdx(idx)">
                                                <span>{{ item.name }}</span>
                                                <div class="line"></div>
                                            </a>
                                        </div>
                                    </template>

<div class="tab-viewall">
  <a href="/cate">查看全部</a>
</div>
</div>

<template v-for="(item, idx) in brands">
                                    <div class="product-img-list hidden" :class="brandsIdx == idx ? 'active' : ''"
                                        :key="idx">

                                        <a :href="'/prductdetail?id=' + item.id" target="_blank" class="img-box"
                                            v-for="(child, i) in item.product_lists" :key="i">
                                            <img :src="child.thumimage" alt="">
                                            <span>{{ child.name }}</span>
                                        </a>
                                    </div>
                                </template>

</div>
</div> -->
            <!-- <div class="nav-icrophone nav-product-list" href="/headphone" data-code="headphone">
                            <div class="prdouctName">{{ $t('header.productRec') }}</div>
                            <div class="head-product-bottom">
                                <div class="nav-product-left">

                                    <template v-for="(item, idx) in brandsTop">
                                        <div class="tab-product " :class="idx == brandsTopIdx ? 'active' : ''"
                                            :key="idx">
                                            <a :href="'/catelist?brand_id=' + item.id"
                                                @mouseover="changeBrandTopIdx(idx)">
                                                <span>{{ item.name }}</span>
                                                <div class="line"></div>
                                            </a>
                                        </div>
                                    </template>



                                    <div class="tab-viewall">
                                        <a href="/cate">查看全部</a>
                                    </div>
                                </div>

                                <template v-for="(item, idx) in brandsTop">
                                    <div class="product-img-list hidden" :class="brandsTopIdx == idx ? 'active' : ''"
                                        :key="idx">

                                        <a :href="'/prductdetail?id=' + item.id" target="_blank" class="img-box"
                                            v-for="(child, i) in item.product_lists" :key="i">
                                            <img :src="child.thumimage" alt="">
                                            <span>{{ child.name }}</span>
                                        </a>
                                    </div>
                                </template>
                            </div>
                        </div> -->

            <!-- <div class="nav-icrophone nav-product-list" href="/Mixers" data-code="Mixers">
              <div class="head-product-bottom">
                <div class="nav-product-left">
                  <div class="prdouctName">产品中心</div>
                  <template v-for="(item, idx) in cate">
                    <div class="tab-product" :class="idx == cateIdx ? 'active' : ''" :key="idx">
                      <a :href="'/product?id=' + item.id" @mouseover="changeCateIdx(idx)">
                        <span>{{ item.name }}</span>
                        <div class="line"></div>
                      </a>
                    </div>
                  </template>
                </div>

                <template v-for="(item, idx) in cate">
                  <div class="product-img-list hidden" :class="cateIdx == idx ? 'active' : ''" :key="idx">
                    <a :href="'/prductdetail?id=' + item.id" target="_blank" class="img-box"
                      v-for="(child, i) in item.product_lists" :key="i">
                      <img :src="child.thumimage" alt="" />
                      <span>{{ child.name }}</span>
                    </a>
                  </div>
                </template>
              </div>
            </div>

            <div class="nav-icrophone nav-product-list" href="/Accessories" data-code="Accessories">
              <div class="prdouctName" style="margin-left: 5vw">品牌中心</div>
              <div class="head-product-bottom">
                <div class="nav-product-left">
                  <template v-for="(item, idx) in brandsList">
                    <div class="tab-product" :class="idx == cateTopIdx ? 'active' : ''" :key="idx">
                      <a :href="'/cate?brandId=' + item.id" @mouseover="changeCateTopIdx(idx)">
                        <span>{{ item.name }}</span>
                        <div class="line"></div>
                      </a>
                    </div>
                  </template>
      
                </div>
                <template v-for="(item, idx) in cateTopList">
                  <div class="product-img-list hidden" :class="cateTopIdx == idx ? 'active' : ''" :key="idx">
                    <a :href="'/cate?brandId=' + child.categoryId + '&categoryId=' + child.id
                      " target="_blank" class="img-box" v-for="(child, i) in item.product_lists" :key="i">
                      <div class="landscape">
                        <span>{{ child.name }}</span><img :src="child.iocnimage" alt="" />
                      </div>
                      <div class="line1"></div>
                    </a>
                  </div>
                </template>
              </div>
            </div> -->


          </div>
        </div>
        <div class="product-drop news status" :style="{ height: currentNav == 2 ? showHeight : '0' }">
          <div class="news-box" @mouseover="productDropFunc(2)" @mouseout="productDropOutFunc(2)">
            <template v-if="menu.length > 0">
              <template v-for="(item, idx) in menu[2].children">
                <div class="news-txt" :key="idx">
                  <a :href="item.target">{{ item.title }}</a>
                </div>
              </template>
            </template>
          </div>
        </div>

        <div class="product-drop status" :style="{ height: currentNav == 3 ? showHeight : '0' }">
          <div class="contact-drop" @mouseover="productDropFunc(3)" @mouseout="productDropOutFunc(3)">
            <template v-if="menu.length > 0">
              <template v-for="(item, idx) in menu[3].children">
                <div class="news-txt" :key="idx">
                  <a :href="item.target">{{ item.title }}</a>
                </div>
              </template>
            </template>
          </div>
        </div>

        <div class="product-drop status" :style="{ height: currentNav == 4 ? showHeight : '0' }">
          <div class="support" @mouseover="productDropFunc(4)" @mouseout="productDropOutFunc(4)">
            <template v-if="menu.length > 0">
              <template v-for="(item, idx) in menu[4].children">
                <div class="news-txt" :key="idx">
                  <a :href="item.target">{{ item.title }}</a>
                </div>
              </template>
            </template>
          </div>
        </div>
        <div class="product-drop status" :style="{ height: currentNav == 5 ? showHeight : '0' }">
          <div class="service" @mouseover="productDropFunc(5)" @mouseout="productDropOutFunc(5)">
            <template v-if="menu.length > 0">
              <template v-for="(item, idx) in menu[5].children">
                <div class="news-txt" :key="idx">
                  <a :href="item.target">{{ item.title }}</a>
                </div>
              </template>
            </template>
          </div>
        </div>

        <div class="part-top" :style="{ display: search ? 'none' : 'block' }">
          <div class="right">
            <template v-if="showBack">
              <div class="icon-search" @click="changeSearch"></div>
            </template>
            <template v-else>
              <div class="icon-search1" @click="changeSearch"></div>
            </template>

            <div class=" shoppingShow">
              <span>{{ $t("message.shopping") }}</span>
            </div>

            <div class="select language">
              <span>{{
                language == "zh" ? "中国大陆 (简体中文)" : "Global (English)"
              }}</span>
              <div class="arr"></div>
              <div class="english" style="display: none">
                <template v-for="(item, idx) in languages">
                  <div class="menu" @click="changeLanguage(item.la)" @mouseover="changeIdx(idx)" :key="idx">
                    <div class="text">{{ item.title }}</div>
                    <div v-show="idx == laIdx" class="line"></div>
                  </div>
                </template>

              </div>
            </div>
          </div>
        </div>

        <div class="part-bottom" :style="{ display: search ? 'none' : 'flex' }">
          <a href="/index" class="webIcon-header">
            <img src="@/assets/img/logo.png" alt="" />
          </a>
          <div class="nav-header">
            <template v-for="(item, idx) in menu">
              <a @mouseover="productDropFunc(idx)" @mouseout="productDropOutFunc(idx)"
                :href="item.target ? item.target : '#'" class="first-menu" data-page="index" :key="idx">{{
                  $t(`menu.${item.tag}`) }}</a>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="phone">
      <div class="button" @click="changeMenuStatus">
        <template v-if="menuStatus == false">
          <img src="@/assets/img/phone/open.png" />
        </template>
        <template v-else>
          <img src="@/assets/img/phone/close.png" />
        </template>
        <img class="logo" src="@/assets/img/logo.png" />
      </div>
      <div class="menu" :style="{ display: menuStatus ? 'block' : 'none' }">
        <el-collapse accordion>
          <el-collapse-item>
            <template slot="title">
              <h2>首页</h2>
            </template>
            <h3 @click="navigatorPage('/index')">网站首页</h3>
          </el-collapse-item>
          <el-collapse-item>
            <template slot="title">
              <h2>产品中心</h2>
            </template>
            <h3 @click="navigatorPage('/catelist')">企顺品牌</h3>
            <h3 @click="navigatorPage('/prductlist')">企顺产品</h3>
          </el-collapse-item>
          <el-collapse-item>
            <template slot="title">
              <h2>企业业务</h2>
            </template>
            <h3 @click="navigatorPage('/business')">业务概况</h3>
            <h3 @click="navigatorPage('/core')">核心业务</h3>
          </el-collapse-item>
          <el-collapse-item>
            <template slot="title">
              <h2>关于企顺</h2>
            </template>
            <h3 @click="navigatorPage('/about')">关于企顺</h3>
            <h3 @click="navigatorPage('/news')">新闻中心</h3>
            <h3 @click="navigatorPage('/society')">社会责任</h3>
            <h3 @click="navigatorPage('/csr')">CSR声明</h3>
            <h3 @click="navigatorPage('/connect')">联系我们</h3>
          </el-collapse-item>
          <el-collapse-item>
            <template slot="title">
              <h2>员工关怀</h2>
            </template>
            <h3 @click="navigatorPage('/employee')">员工关怀</h3>
            <h3 @click="navigatorPage('/joinus')">加入我们</h3>
          </el-collapse-item>
          <el-collapse-item>
            <template slot="title">
              <h2>服务与支持</h2>
            </template>
            <h3 @click="navigatorPage('/security')">安全代码</h3>
            <h3 @click="navigatorPage('/hotline')">服务热线</h3>
            <h3 @click="navigatorPage('/question')">常见问题</h3>
            <h3 @click="navigatorPage('/download')">下载中心</h3>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import header from "@/api/header";
import { getCategory } from "@/api/headerapi";
import { getProductCenter, getBrandCenter } from "@/api/index";
// import { RouterLink } from 'vue-router';
export default {
  name: "HeaderComp",
  data() {
    return {
      showHeight: "100vh",
      currentNav: 0,
      showBack: false,
      scrollPosition: 0,
      menu: [],
      language: "zh",
      brands: [],
      brandsIdx: 0,
      brandsTop: [],
      brandsTopIdx: 0,
      cate: [],
      cateIdx: 0,
      cateTop: [],
      cateTopList: [],
      cateTopIdx: 0,
      search: false,
      searchData: "",
      activeNames: ["1"],
      menuStatus: false,
      brandsList: [],
      laIdx: 0,
      languages: [
        {
          "title": "中国大陆（简体中文）",
          "la": 'zh'
        },
        {
          "title": "Global (English)",
          "la": 'en'
        }
      ],
      cate1: [

      ]
    };
  },
  mounted() {
    // 加入监听滚动
    window.addEventListener("scroll", this.handleScroll);
    this.menu = header.menu;

    getBrandCenter().then((res) => {

      if (res.code == 1) {
        res.data.forEach((element) => {
          this.cate1.push({ id: element.id, name: element.name, iocncheckimage: element.iocncheckimage })
        });
      }
    })

    getProductCenter().then((res) => {

      if (res.code == 1) {
        res.data.forEach((element) => {
          let product_lists = [];
          this.brandsList.push({ name: element.name, id: element.id });


          // 在这里为每个 cate_list 中的对象添加 categoryId
          element.cate_lists.forEach((cate) => {
            cate.categoryId = element.id;
          });

          product_lists.push(...element.cate_lists);

          this.cateTopList.push({ product_lists });
        });
      }
    });



    getCategory().then((res) => {
      if (res.code == 1) {
        this.cate = res.data.category_list;
        this.cateTop = res.data.category_top_list;
      }
    });

    if (localStorage.language) {
      this.$i18n.locale = localStorage.language;
      this.language = localStorage.language;
    }

    this.$bus.$on("showback", (bool) => {
      this.showBack = bool;
    });
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    changeIdx(idx) {
      this.laIdx = idx
    },
    navigatorPage(url) {
      this.$router.push(url);
      this.menuStatus = false;
    },
    changeMenuStatus() {
      this.menuStatus = !this.menuStatus;
    },
    handleChange(val) {
      console.log(val);
    },
    changeSearch() {
      this.search = !this.search;
      this.showBack = true;
    },
    closeSearch() {
      this.search = !this.search;
      this.showBack = false;
    },
    changeBrandIdx(idx) {
      this.brandsIdx = idx;
    },
    changeBrandTopIdx(idx) {
      this.brandsTopIdx = idx;
    },
    changeCateIdx(idx) {
      this.cateIdx = idx;
    },
    changeCateTopIdx(idx) {
      this.cateTopIdx = idx;
    },
    handleScroll() {
      this.scrollPosition = window.scrollY;
      if (this.search) {
        this.showBack = true;
        return;
      }
      if (this.scrollPosition > 0) {
        this.showBack = true;
      } else {
        this.showBack = false;
      }
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.language = lang;
      this.language = lang;
    },
    productDropFunc(idx) {
      this.currentNav = idx;
      this.showBack = true;
      // this.$refs.productDrop.style.height = '100vh';
    },
    productDropOutFunc(idx) {
      this.currentNav = 0;
      if (this.scrollPosition <= 10) {
        this.showBack = false;
      }
      console.log(idx);
      console.log(this.showBack);
      // this.productShow = 0
      // this.$refs.productDrop.style.height = '0';
    },
    searchProduct() {
      if (this.searchData == "") {
        return false;
      }
      this.$router.push({
        path: "/search",
        query: { keyword: this.searchData },
      });
    },
    navMote(){
      this.productDropOutFunc(0)
      this.$router.push({
        path: "/prductlist",
      });
    },
    navPage(id){

      this.productDropOutFunc(0)
      this.$router.push({
        path: "/prductlist",
        query: { id },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.header-category {
  width: 7vw;
  height: 7vw;
  transform: scale(1);
  transition: all 0.3s linear;

  &:hover {
    transform: scale(1.03);
  }

}

.mote {
  width: 4vw;
  background-color: #f6f6f6;
  position: absolute;
  right: 0;
  height: 2vw;
  border-radius: 30%;
  text-align: center;
  line-height: 2vw;
  color: #D7D7D7;
  cursor: pointer;
  &:hover{
    color: white;
    background-color: #007bff;
  }
}

@media (min-width: 768px) {
  .phone {
    display: none;
  }

  .heder-container {
    position: fixed;
    top: 0;
    z-index: 11;
    width: 100vw;
  }

  .poup {
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.4);
  }

  .section-header {
    position: relative;
    width: 100%;
    /* height: auto; */
    height: 6.35416vw;
    padding-left: 4.11458vw;
    padding-right: 3.17708vw;
    padding-top: 0.83333vw;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 12;
    box-sizing: border-box;
    transition: all 0.3s linear;
    color: white;

    .product-drop {
      width: 100%;
      height: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 6.35415vw;
      transition: all 0.3s ease-in;
      background: rgba(0, 0, 0, 0.4);
      z-index: 20;

      .title {
        width: 100%;
        /* height: 3.125vw; */
        padding: 0.625vw 5.78125vw;
        display: flex;
        align-items: center;
        background-color: #f9f9f9;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
        font-size: 0.9375vw;
        color: #000;
        font-weight: bold;

        .closeNav {
          width: 1.875vw;
          height: 1.875vw;
          cursor: pointer;
          background: url(../assets/front/icon-closenav.png);
          background-size: 100% 100%;
          transition: all 0.5s;
        }
      }

      .part-product-nav {
        width: 100%;
        height: auto;
        background-color: #fff;
        box-sizing: border-box;
        padding: 0 5.72916vw;
        padding-top: 1.30208vw;
        padding-bottom: 2.86458vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
        border-radius: 0px 0px 1.0416vw 1.0416vw;

        .nav-product-list {
          width: 41.35416vw;
          height: 28.22916vw;
          background-color: #f9f9f9;
          padding-top: 2.03125vw;
          padding-left: 2.39583vw;
          padding-right: 2.60416vw;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;

          .prdouctName {
            width: 40%;
            font-size: 1.25vw;
            color: #000;
            margin-bottom: 1.35416vw;
            font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .head-product-bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .nav-product-left {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 17.2083vw;
              font-size: 0.83333vw;
              line-height: 1.2;
              color: #959595;

              .tab-product {
                width: 40%;
                margin-bottom: 0.98958vw;
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: center;
                z-index: 12;
                flex-direction: row;


                a {
                  font-size: 0.83333vw;
                  color: #959595;
                }

                .line {
                  width: 3.125vw;
                  height: 1.04166vw;
                  position: absolute;
                  // left: -1.5625vw;
                  top: 0;
                  z-index: -1;
                  background: linear-gradient(128deg, #519bff, #35fdff);
                  border-radius: 0.52083vw;
                  opacity: 0;
                  transition: all 0.2s ease-in;
                }
              }

              .active {
                .line {
                  opacity: 1;
                }
              }
            }

            .product-img-list {
              display: flex;
              flex-direction: column;
              width: 17.29166vw;

              .img-box {
                width: 7.29166vw;
                height: auto;
                margin-bottom: 0.83333vw;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 0.83333vw;
                color: #959595;
                align-items: center;
                transition: opacity 0.3s ease-out;
                position: relative;
                cursor: pointer;

                img {
                  width: 7.29166vw;
                  height: 6.04166vw;
                  border-radius: 0.4166vw;
                  margin-bottom: 0.3125vw;
                  transition: all 0.3s linear;
                }

                .landscape {
                  display: flex;
                  align-items: center;
                  height: 2vw;
                  z-index: 2;

                  img {
                    height: 2vw;
                    width: 2vw;
                    margin-bottom: 0;
                    margin-left: 0.5vw;
                  }
                }

                .line1 {
                  width: 3.125vw;
                  height: 1.04166vw;
                  position: absolute;
                  left: -1vw;
                  z-index: 0;
                  background: linear-gradient(128deg, #519bff, #35fdff);
                  border-radius: 0.52083vw;
                  opacity: 0;
                  transition: all 0.2s ease-in;
                }

                &:hover {
                  .line1 {
                    opacity: 1;
                  }

                  img {
                    transform: scale(1.03);
                  }
                }
              }


            }

            .hidden {
              display: none;
            }

            .active {
              display: flex;
            }
          }
        }
      }

      .news-box {
        background-color: #fff;
        width: 8.3333vw;
        box-sizing: border-box;
        padding: 2.0833vw 0 2.0833vw 2.3958vw;
        border-radius: 0 0 1.1vw 1.1vw;
        position: absolute;
        right: 30.05vw;

        // z-index: 100;
        .news-txt {
          margin-bottom: 1.5625vw;

          a {
            position: relative;
            font-size: 0.8333vw;
            line-height: 1.5;
            color: #959595;
            z-index: 11;
            transition: all 0.3s ease-in;
          }

          a::after {
            position: absolute;
            content: "";
            left: -1.3541vw;
            bottom: 0;
            width: 3.125vw;
            height: 1.0416vw;
            background: linear-gradient(128deg, #519bff, #35fdff);
            border-radius: 0.52083vw;
            // background: url(../assets/front/title-line.png) no-repeat;
            background-size: 100% 100%;
            z-index: -1;
            opacity: 0;
            transition: all 0.3s ease-in;
          }

          a:hover {
            &::after {
              opacity: 1;
            }
          }
        }
      }

      .news-box::before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 100%;
        width: 100%;
        height: 100%;
        z-index: 100;
      }

      .contact-drop {
        background-color: #fff;
        width: 8.3333vw;
        box-sizing: border-box;
        padding: 2.0833vw 0 2.0833vw 2.3958vw;
        border-radius: 0 0 1.1vw 1.1vw;
        position: absolute;
        right: 20.6vw;

        // z-index: 100;
        .news-txt {
          margin-bottom: 1.5625vw;

          a {
            position: relative;
            font-size: 0.8333vw;
            line-height: 1.5;
            color: #959595;
            z-index: 11;
            transition: all 0.3s ease-in;
          }

          a::after {
            position: absolute;
            content: "";
            left: -1.3541vw;
            bottom: 0;
            width: 3.125vw;
            height: 1.0416vw;
            // background: url(../assets/front/title-line.png) no-repeat;
            background: linear-gradient(128deg, #519bff, #35fdff);
            border-radius: 0.52083vw;
            background-size: 100% 100%;
            z-index: -1;
            opacity: 0;
            transition: all 0.3s ease-in;
          }

          a:hover {
            &::after {
              opacity: 1;
            }
          }
        }
      }

      .contact-drop::before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 100%;
        width: 100%;
        height: 100%;
        z-index: 100;
      }

      .support {
        background-color: #fff;
        width: 8.3333vw;
        box-sizing: border-box;
        padding: 2.0833vw 0 2.0833vw 2.3958vw;
        border-radius: 0 0 1.1vw 1.1vw;
        position: absolute;
        right: 11.2vw;

        // z-index: 100;
        .news-txt {
          margin-bottom: 1.5625vw;

          a {
            position: relative;
            font-size: 0.8333vw;
            line-height: 1.5;
            color: #959595;
            z-index: 11;
            transition: all 0.3s ease-in;
          }

          a::after {
            position: absolute;
            content: "";
            left: -1.3541vw;
            bottom: 0;
            width: 3.125vw;
            height: 1.0416vw;
            // background: url(../assets/front/title-line.png) no-repeat;
            background: linear-gradient(128deg, #519bff, #35fdff);
            border-radius: 0.52083vw;
            background-size: 100% 100%;
            z-index: -1;
            opacity: 0;
            transition: all 0.3s ease-in;
          }

          a:hover {
            &::after {
              opacity: 1;
            }
          }
        }
      }

      .support::before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 100%;
        width: 100%;
        height: 100%;
        z-index: 100;
      }

      .service {
        background-color: #fff;
        width: 8.3333vw;
        box-sizing: border-box;
        padding: 2.0833vw 0 2.0833vw 2.3958vw;
        border-radius: 0 0 1.1vw 1.1vw;
        position: absolute;
        right: 1.25vw;

        // z-index: 100;
        .news-txt {
          margin-bottom: 1.5625vw;

          a {
            position: relative;
            font-size: 0.8333vw;
            line-height: 1.5;
            color: #959595;
            z-index: 11;
            transition: all 0.3s ease-in;
          }

          a::after {
            position: absolute;
            content: "";
            left: -1.3541vw;
            bottom: 0;
            width: 3.125vw;
            height: 1.0416vw;
            // background: url(../assets/front/title-line.png) no-repeat;
            background: linear-gradient(128deg, #519bff, #35fdff);
            border-radius: 0.52083vw;
            background-size: 100% 100%;
            z-index: -1;
            opacity: 0;
            transition: all 0.3s ease-in;
          }

          a:hover {
            &::after {
              opacity: 1;
            }
          }
        }
      }

      .service::before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 100%;
        width: 100%;
        height: 100%;
        z-index: 100;
      }
    }

    .part-search {
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .search-logo {
        width: 10.26041vw;
        margin-top: 0.8vw;

        img {
          display: block;
          width: 100%;
          height: 2.5vw;
        }
      }

      .search-input {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 37.5vw;
        box-sizing: border-box;
        padding: 0 2.08333vw;

        .input-icon {
          display: block;
          width: 1.35416vw;
          margin-right: 1.04166vw;
          flex-shrink: 0;
          cursor: pointer;
        }

        input {
          width: 100%;
          font-size: 0.9375vw;
          color: #000;
          border: none;
          outline: none;
          padding: 1vw;
          border: 1px solid #cbcaca;
          border-radius: 1vw;
        }
      }

      .close {
        width: 1.04166vw;
        height: 1.04166vw;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        // background: url(../icon/search-close.png);
        background: url(../assets/front/icon-closenav.png);
        background-size: 80% 80%;
        background-position: center center;
        transition: all 0.5s;
      }
    }
  }

  .part-top {
    width: 100%;
    position: relative;
    min-height: 1.45833vw;
    z-index: 99;

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      right: 0;

      .icon-search {
        width: 1.14583vw;
        height: 1.14583vw;
        background: url(../assets/front/icon-search-black.png) no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        margin-right: 1.61458vw;
      }

      .icon-search1 {
        width: 1.14583vw;
        height: 1.14583vw;
        background: url(../assets/front/icon-search.png) no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        margin-right: 1.61458vw;
      }

      .select {
        width: 8vw;
        font-size: 0.67708vw;
        cursor: pointer;
        margin-right: 1.04166vw;
        height: 1.45833vw;
        line-height: 1.45833vw;
        border-radius: 0.72916vw;
        padding-left: 1vw;
        padding-right: 0.5vw;

        .english {

          display: flex;
          flex-wrap: wrap;
          // width: 6vw;
          background-color: white;
          color: #959595;
          padding: 0.5vw;
          margin-top: 0.1vw;
          border-radius: 0.2vw;

          .menu {
            position: relative;

            .text {
              z-index: 10000;
              // position: absolute;
              position: relative;
            }

            .line {
              width: 3.125vw;
              height: 1.04166vw;
              position: absolute;
              top: 0.2vw;
              background: linear-gradient(128deg, #519bff, #35fdff);
              border-radius: 0.52083vw;
              // opacity: 0;
              transition: all 0.2s ease-in;
            }

            div {
              font-size: 0.6vw;
            }

            div:hover {
              font-weight: 700;
            }
          }

        }

        .english:hover {
          display: block !important;
        }
      }

      .language:hover {
        .english {
          display: block !important;
        }
      }

      .select:hover {
        // background-color: #4196f6;
        background-color: white;
        color: #959595;
        // color: white;
      }

      .shoppingShow {
        font-size: 0.67708vw;
        cursor: pointer;
        margin-right: 1.04166vw;
        height: 1.45833vw;
        line-height: 1.45833vw;
        border-radius: 0.72916vw;
        padding-left: 1vw;
        padding-right: 0.5vw;
      }

      .shoppingShow:hover {
        background-color: white;
        color: #959595;

      }
    }
  }

  .part-bottom {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 50;

    .webIcon-header {
      width: 10.26041vw;
      height: 2.5vw;

      img {
        display: block;
        width: 100%;
      }
    }

    .nav-header {
      margin-top: 1vw;
      display: flex;
      flex-direction: row;
      align-items: center;

      a {
        display: block;
        padding-bottom: 0.36458vw;
        // color: #fff;
        font-weight: 500;
        font-size: 1vw;
        margin-right: 5.41666vw;
        position: relative;
       
      }

      a:last-of-type {
        margin-right: 0;
      }

      .first-menu:hover {
        border-bottom: 2px solid;
      }
    }
  }

  .heder-container {
    a {
      color: white;
      text-shadow:1px 1px 1px #777;
    }
  }

  .heder-container-white {
    background-color: white;
    color: #000000;

    a {
      color: #000;
      text-shadow:0px 0px 0px #777;
    }
  }
}

@media (max-width: 768px) {
  .heder-container {
    display: none;
  }

  .phone {
    width: 100vw;

    // position: fixed;
    // top: 0;
    z-index: 1000;
    background-color: white;

    .menu {
      width: 80vw;
      height: 100vh;
      margin: auto;
      padding-left: 2rem;
    }

    .button {
      padding: 0.5rem;
      width: 1rem;
      height: 1rem;
      display: flex;

      img {
        width: 100%;
        height: 100%;
      }

      .logo {
        width: 5rem;
        height: 1rem;
        margin-left: 7rem;
      }
    }
  }
}
</style>