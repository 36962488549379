import VueRouter from "vue-router";
import NotFound from "@/view/NotFound";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        component: () => import('@/view/IndexVue'),
        meta: {
            title: "keesion"
        }
    },
    {
        path: '/index',
        component: () => import('@/view/IndexVue'),
        meta: {
            title: "keesion"
        }
    },
    {
        path: '/product',
        component: () => import('@/view/ProductVue'),
        meta: {
            title: "产品中心"
        },
        children: [
           
        ]
    },
    {
        path: '/cate',
        component: () => import('@/view/CategoryVue'),
        meta: {
            title: "分类"
        }
    },
    {
        path: '/catelist',
        component: () => import('@/view/CategoryList'),
        meta: {
            title: "分类"
        }
    },
    {
        path: '/prductdetail',
        component: () => import('@/view/ProductDetail'),
        meta: {
            title: "产品中心详情"
        },
    },
    {
        path: '/prductlist',
        component: () => import('@/view/ProductList'),
        meta: {
            title: "产品列表"
        },
    },
    {
        path: '/business',
        component: () => import('@/view/BusinessVue'),
        meta: {
            title: "业务概况"
        },
    },
    {
        path: '/core',
        component: () => import('@/view/CoreVue'),
        meta: {
            title: "核心业务"
        },
    },
    {
        path: '/about',
        component: () => import('@/view/AboutVue'),
        meta: {
            title: "关于企顺"
        },
    },
    {
        path: '/society',
        component: () => import('@/view/SocietyVue'),
        meta: {
            title: "社会责任"
        },
    },
    {
        path: '/csr',
        component: () => import('@/view/CsrVue'),
        meta: {
            title: "csr"
        },
    },
    {
        path: '/news',
        component: () => import('@/view/NewsVue'),
        meta: {
            title: "新闻"
        },
    },
    {
        path: '/newsdetail',
        component: () => import('@/view/NewsDetail'),
        meta: {
            title: "新闻详情"
        },
    },
    {
        path: '/connect',
        component: () => import('@/view/ConnectVue'),
        meta: {
            title: "联系我们"
        },
    },
    {
        path: '/employee',
        component: () => import('@/view/EmployeeVue'),
        meta: {
            title: "员工关怀"
        },
    },
    {
        path: '/joinus',
        component: () => import('@/view/JoinusVue'),
        meta: {
            title: "加入我们"
        },
    },
    {
        path: '/security',
        component: () => import('@/view/SecurityVue'),
        meta: {
            title: "安全代码"
        },
    },
    {
        path: '/hotline',
        component: () => import('@/view/HotlineVue'),
        meta: {
            title: "服务热线"
        },
    },   
    {
        path: '/question',
        component: () => import('@/view/QuestionVue'),
        meta: {
            title: "常见问题"
        },
    },   
    {
        path: '/download',
        component: () => import('@/view/DownloadVue'),
        meta: {
            title: "下载中心"
        },
    },  
    {
        path: '/search',
        component: () => import('@/view/SearchVue'),
        meta: {
            title: "搜索结果"
        },
    },   
    {
        path: '*',
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    routes, // (缩写) 相当于 routes: routes
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
    const title = to.meta.title;

    if (title) {
        document.title = title;
    }

    next()
})

export default router