<template>
  <div>
    <div class="main">
      <swiper :options="swiperOption">
        <template v-for="(item, idx) in banners">
          <swiper-slide :key="idx" v-if="item.file_type == 'video'">
            <div style="position: relative">
              <video
                class="video"
                style=""
                playsinline="true"
                preload="metadata"
                muted="muted"
                loop="loop"
                autoplay
                :poster="item.coverimage"
              >
                <source :src="item.poster_file" type="video/mp4" />
              </video>
            </div>
          </swiper-slide>
          <swiper-slide v-if="item.file_type == 'image'" :key="idx">
            <div class="banner" :style="styleObj">
              <img :src="item.poster_file" />
            </div>
          </swiper-slide>
        </template>
      </swiper>
      <div class="swiper-button-prev my-custom-prev" :style="top"></div>
      <div class="swiper-button-next my-custom-next" :style="top"></div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  props: {
    banners: {
      type: Array,
      default: function () {
        return [];
      },
    },
    swiperOption: {
      type: Object,
      default: function () {
        return {
          autoplay: {
            delay: 12000,
          },
          navigation: {
            nextEl: ".my-custom-next",
            prevEl: ".my-custom-prev",
          },
        };
      },
    },
    styleObj: {
      type: Object,
      default: function () {
        return {
          width: "100vw",
          height: "100vh",
        };
      },
    },
    top: {
      type: Object,
      default: function () {
        return {
          top: "25%",
        };
      },
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>
<style scoped lang="scss">
@media (min-width: 768px) {
  .main {
    height: 50vh;  /* 容器高度减半 */
    .my-custom-prev {
      width: 3.125vw;
      height: 1.6vw;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../assets/img/left-icon.png");
    }

    .my-custom-prev::after {
      content: "";
    }

    .my-custom-prev:hover {
      background-image: url("../assets/img/swiper-prev-head-active.png");
    }

    .my-custom-next {
      width: 3.125vw;
      height: 1.6vw;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../assets/img/right-icon.png");
    }

    .my-custom-next::after {
      content: "";
    }

    .my-custom-next:hover {
      background-image: url("../assets/img/swiper-next-head-active.png");
    }
  }

  .banner {
    width: 100vw;
    height: 50vh;  /* 图片高度减半 */

    img {
      width: 100%;
      height: 50%;
      object-fit: cover;
    }
  }

  .video {
    width: 100vw;
    height: 50vh;  /* 视频高度减半 */
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .main {
    height: 50vw;  /* 小屏幕下，容器高度设置为50vw */

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  .video {
    width: 100vw;
    height: 50vw;
    object-fit: cover;
  }

  .banner {
    width: 100vw;
    height: 50vw;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>