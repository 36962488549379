<template>
    <div>
        <div class="section-footer">
            <div class="footer-top">
                <div class="row marginBottom1o7">
                    <!--底部nav-->
                    <div class="part-nav">
                        <div class="list-nav">
                            <h2>产品与品牌</h2>
                            <h3>
                                <a href="/product" target="_blank">产品推荐</a>
                            </h3>
                            <h3>
                                <a href="/cate" target="_blank">分类推荐</a>
                            </h3>

                        </div>
                        <div class="list-nav">
                            <h2>关于企顺</h2>
                            <h3>
                                <a href="/about" target="_blank">关于企顺</a>
                            </h3>
                            <h3>
                                <a href="/news" target="_blank">新闻中心</a>
                            </h3>
                            <h3>
                                <a href="/connect" target="_blank">联系我们</a>
                            </h3>
                        </div>
                        <div class="list-nav">
                            <h2>企顺创新</h2>
                            <h3>
                                <a href="/employee" target="_blank">员工关怀</a>
                            </h3>
                            <h3>
                                <a href="/hotline" target="_blank">服务与支持</a>
                            </h3>
                        </div>
                        <div class="list-nav">
                            <h2>友情链接</h2>
                            <h3><a href="/" target="_blank">企顺商城</a></h3>

                        </div>

                    </div>
                    <!-- icon -->
                    <div class="top-right">
                        <img src="@/assets/img/logo.png" class="bottomBOYA" alt="">
                        <div class="box-media">
                            <img src="@/assets/img/qr.png" />
                        </div>
                    </div>
                </div>

            </div>


            <div class="footer-bottom">
                <div class="right">
                    <a href="/about" target="_blank">© 2024, Keesion. Powerd by Shopify</a>
                    <!-- <a href="/privacyPolicy" target="_blank">隐私声明</a>
                    <a href="/siteMap" target="_blank">网站地图</a>
                    <div class="friend-links">
                        <span>友情链接</span>
                        <img src="/static/boya/icon/arr-botton.png" class="arr top" alt="">

                        <div class="links">
                            <div class="links-box">
                                <a href="https://cn.saramonic.com/" target="_blank">Saramonic枫笛</a>
                            </div>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="left">Copyright © 2024 BOYA博雅 粤ICP备12014417号</div> -->
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "FooterComp"
}
</script>

<style lang="scss" scoped>
@media (max-width:768px) {
    .section-footer {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding-top: 1rem;

        .footer-top {
            display: flex;
            flex-direction: column;
            padding: 0 11.97916vw;
            box-sizing: border-box;
            padding-bottom: 1.09375vw;

            .row {
                width: 100%;
                display: flex;
                flex-direction: row;
                position: relative;
                justify-content: space-between;
                position: relative;

                .part-nav {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;

                    .list-nav {
                        display: flex;
                        flex-direction: column;
                        margin-right: 4.01041vw;

                        h2 {
                            color: #000;
                            font-size: 0.8rem;
                            font-weight: bold;
                        }

                        h3 {
                            margin-top: 0.5rem;
                            margin-bottom: 0.5rem;

                            a {
                                color: #a3a3a3;
                                cursor: pointer;
                                font-size: 0.5rem;
                            }
                        }
                    }
                }

                .top-right{
                    display: none;
                }

                .bottomBOYA {
                    width: 10.47916vw;
                    height: 2.19791vw;
                    position: absolute;
                    right: 0;
                    /* top: -0.3125vw; */
                }

                .box-media {
                    position: absolute;
                    right: 1.5vw;
                    bottom: 0.3125vw;
                    display: flex;
                    flex-direction: row;

                    img {
                        width: 7vw;
                        height: 7vw;
                    }
                }
            }
        }

        .footer-bottom {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            padding: 0 11.97916vw;
            border-top: 1px solid #ddd;
            box-sizing: border-box;
            justify-content: space-between;

            .right {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 0.5rem;
                position: relative;

                a {
                    color: #a3a3a3;
                    font-size: 0.5rem;
                    margin-right: 2.08333vw;
                    padding-top: 1.05vw;
                    padding-bottom: 1.2vw;
                }

                .friend-links {
                    color: #a3a3a3;
                    cursor: pointer;
                    padding-top: 1.05vw;
                    padding-bottom: 1.2vw;
                    position: relative;


                }

                .links {
                    /* width: 5.46161vw; */
                    /* background: url(../icon/links-img.png); */
                    background-color: #fff;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                    border-radius: 0.5vw;
                    background-size: 100% 100%;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 3vw;
                    display: none;
                    flex-direction: column;
                    justify-content: space-between;
                    z-index: 999;

                    .links-box {
                        overflow-y: auto;
                        /* width: 5.16161vw; */
                        max-height: 8vw;
                        /* margin-right: 0.26041vw; */
                        position: relative;
                        z-index: 1000;
                        overscroll-behavior: contain;
                        display: flex;
                        flex-direction: column;
                    }
                }
            }

            .left {
                font-size: 0.625vw;
                color: #a3a3a3;
            }
        }
    }
}

@media (min-width:768px) {
    .section-footer {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding-top: 3.85416vw;

        .footer-top {
            display: flex;
            flex-direction: column;
            /* padding: 5vw 7.23958vw 0.78125vw 6.09375vw; */
            padding: 0 11.97916vw;
            box-sizing: border-box;
            padding-bottom: 1.09375vw;

            .row {
                width: 100%;
                display: flex;
                flex-direction: row;
                position: relative;
                justify-content: space-between;
                position: relative;

                .part-nav {
                    display: flex;
                    flex-direction: row;

                    .list-nav {
                        display: flex;
                        flex-direction: column;
                        margin-right: 4.01041vw;

                        h2 {
                            color: #000;
                            font-size: 0.9375vw;
                            margin-bottom: 1.92708vw;
                            font-weight: bold;
                        }

                        h3 {
                            margin-bottom: 0.375vw;

                            a {
                                color: #a3a3a3;
                                cursor: pointer;
                                font-size: 0.72916vw;
                            }
                        }
                    }
                }

                .bottomBOYA {
                    width: 10.47916vw;
                    height: 2.19791vw;
                    position: absolute;
                    right: 0;
                    /* top: -0.3125vw; */
                }

                .box-media {
                    position: absolute;
                    right: 1.5vw;
                    bottom: 0.3125vw;
                    display: flex;
                    flex-direction: row;

                    img {
                        width: 7vw;
                        height: 7vw;
                    }
                }
            }
        }

        .footer-bottom {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            padding: 0 11.97916vw;
            border-top: 1px solid #ddd;
            box-sizing: border-box;
            justify-content: space-between;

            .right {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 0.72916vw;
                position: relative;

                a {
                    color: #a3a3a3;
                    font-size: 0.72916vw;
                    margin-right: 2.08333vw;
                    padding-top: 1.05vw;
                    padding-bottom: 1.2vw;
                }

                .friend-links {
                    color: #a3a3a3;
                    cursor: pointer;
                    padding-top: 1.05vw;
                    padding-bottom: 1.2vw;
                    position: relative;


                }

                .links {
                    /* width: 5.46161vw; */
                    /* background: url(../icon/links-img.png); */
                    background-color: #fff;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                    border-radius: 0.5vw;
                    background-size: 100% 100%;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 3vw;
                    display: none;
                    flex-direction: column;
                    justify-content: space-between;
                    z-index: 999;

                    .links-box {
                        overflow-y: auto;
                        /* width: 5.16161vw; */
                        max-height: 8vw;
                        /* margin-right: 0.26041vw; */
                        position: relative;
                        z-index: 1000;
                        overscroll-behavior: contain;
                        display: flex;
                        flex-direction: column;
                    }
                }
            }

            .left {
                font-size: 0.625vw;
                color: #a3a3a3;
            }
        }
    }
}
</style>