<template>
  <div id="app">
    <HeaderComp></HeaderComp>

    <router-view></router-view>

    <FooterComp></FooterComp>
    <el-backtop></el-backtop>

  </div>
</template>

<script>
import HeaderComp from '@/components/HeaderComp.vue';
import FooterComp from '@/components/FooterComp.vue';

// import { RouterView } from 'vue-router';

export default {
  name: 'App',
  components: {
    HeaderComp,
    FooterComp,
  }
}
</script>

<style>
body,
#app {
  overflow-x: hidden;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
}

#app {
  background-color: #f9f9f9;
  min-height: 100vh;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "TencentSansw7";
  src: url("./assets/font/TencentSans-W7.woff");
}
</style>
