import Vue from 'vue'
import App from './App.vue'
import './assets/css/global.css'
import i18n from './libs/i18n'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueRouter from 'vue-router'
import router from './router';
import 'swiper/css/swiper.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {
  WOW
} from 'wowjs';
import 'wowjs/css/libs/animate.css';

import BannerComp from './components/BannerComp.vue'
import BannerComp2 from './components/BannerComp2.vue'
import TitleComp from './components/TitleComp.vue'

//这里需要将样式用起来，
Vue.use(VueRouter)
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI);
Vue.component('BannerComp', BannerComp)
Vue.component('BannerComp2', BannerComp2)
Vue.component('TitleComp', TitleComp)

Vue.prototype.$bus = new Vue()

Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // 需要执行动画的元素的 class
  animateClass: 'animated', //animation.css 动画的 class
  offset: 0, // 距离可视区域多少开始执行动画
  mobile: false, // 是否在移动设备上执行动画
  live: false, // 异步加载的内容是否有效
});

Vue.mixin({
  mounted: function () {
    this.$nextTick(() => {
      this.$wow.init();
    });
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')